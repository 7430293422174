<template>
  <v-app>
    <div class="backgruond"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col lg="5" class="mx-auto mt-200">
        <v-card class="pa-10">
          <div class="text-center">
            <v-avatar size="100" color="accent lighten-4">
              <v-icon size="40" color="accent">mdi-account</v-icon>
            </v-avatar>
            <h2 class="accent--text">Student Partner Registration</h2>
          </div>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
              outlined
            >
            </v-text-field>

            <v-file-input
              v-model="profilePicture"
              show-size
              :rules="profilePictureRules"
              append-icon="mdi-camera"
              prepend-icon=""
              accept="image/png, image/jpeg, image/bmp"
              label="Choose Profile Image"
              outlined
            ></v-file-input>

            <v-text-field
              v-model="emailId"
              :rules="emailIdRules"
              label="E-mail"
              required
              outlined
            >
            </v-text-field>

            <div>
              <b style="color: red"> * </b>If you cannot find your college
              <a href="" target="_blank" style="text-decoration: none">
                Open Link
                <v-icon color="primary" small> mdi-open-in-new </v-icon>
              </a>'
            </div>

            <v-autocomplete
              v-model="collegeId"
              :items="colleges"
              :rules="collegeRules"
              label="College"
              item-text="Name"
              item-value="Id"
              hint=''
              persistent-hint
              outlined
            ></v-autocomplete>

            <v-select
              v-model="year"
              :rules="yearRules"
              :items="years"
              label="Year Of Study"
              outlined
            ></v-select>

            <v-text-field
              v-model="rollNumber"
              :rules="rollNumberRules"
              label="Roll Number"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="upiId"
              :rules="upiIdRules"
              label="UPI Id"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="phoneNumber"
              :rules="phoneNumberRules"
              :counter="10"
              label="Mobile Number"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="linkedInProfileLink"
              label="LinkedIn Profile"
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              label="Password"
              type="password"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="confirmPassword"
              label="Confirm Password"
              :rules="confirmPasswordRules"
              type="password"
              required
              outlined
            >
            </v-text-field>
            <v-btn color="accent" type="submit" :loading="loading">
              Register
            </v-btn>
            &nbsp;
            <v-btn color="accent" outlined @click="goToLogin"> Login </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-main>

    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase/app";

export default {
  name: "App",

  data: () => ({
    name: "",
    emailId: "",
    profilePictureUrl: "",
    phoneNumber: "",
    linkedInProfileLink: "",
    collegeId: "",
    rollNumber: "",
    profilePicture: null,
    alertType: null,
    alertMessage: null,
    loading: false,
    snackbar: false,
    passwordShow: false,
    password: "",
    confirmPassword: "",
    isUploadSuccess: false,
    upiId: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be 6  characters or more!",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 45) || "Name must be less than 45 characters",
    ],
    emailIdRules: [
      (v) => !!v || "E-mail Id is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    profilePictureRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    collegeRules: [
      (v) => !!v || "college is required",
      (v) => (v && v != null) || "College is required",
    ],
    phoneNumberRules: [
      (v) => !!v || "Mobile Number is required",
      (v) => (v && v.length == 10) || "Invalid Mobile Number",
    ],
    upiIdRules: [(v) => !!v || "UPI ID is required"],
    rollNumberRules: [(v) => !!v || "Roll Number is required"],
    valid: true,
    colleges: [],
    years: ["1", "2", "3", "4"],
    year: "",
    yearRules: [(v) => !!v || "Year is required"],
    uploadValue: 0,
    img1: null,
  }),
  computed: {
    confirmPasswordRules() {
      return [
        (v) => !!v || "Password is required",
        (v) => v == this.password || "Password is not matching!!!",
      ];
    },
  },
  methods: {
    ...mapActions(["postStudentPartner", "getColleges"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.profilePicture == null) this.signUp();
        else this.uploadImage();
      }
    },
    Alert(type, message) {
      (this.alertType = type), (this.alertMessage = message);
      this.snackbar = true;
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (allCookies.currentUserId) {
        this.$router.push("/Dashboard");
      }
    },
    goToLogin() {
      this.$router.push("Home");
    },
    async signUp() {
      var isSuccessfull = await this.postStudentPartner({
        Name: this.name,
        ProfilePictureUrl: encodeURIComponent(this.profilePictureUrl),
        CollegeId: this.collegeId,
        Year: this.year,
        RollNumber: this.rollNumber,
        EmailId: this.emailId,
        PhoneNumber: this.phoneNumber,
        UPIId: this.upiId,
        LinkedInProfileLink: this.linkedInProfileLink,
        Password: this.password,
      });
      if (isSuccessfull == true) {
        this.alertMessage = "Registered Successfully!";
        this.alertType = "green";
        this.snackbar = true;
        this.loading = false;
      } else {
        this.alertMessage = `Registration failed!${isSuccessfull}`;
        this.alertType = "red";
        this.snackbar = true;
        this.loading = false;
      }
    },
    uploadImage() {
      this.img1 = null;
      const storageRef = firebase
        .storage()
        .ref(`SpProfilePics/${this.profilePicture.name}`)
        .put(this.profilePicture);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {
          this.alertType = 0;
          this.alertMessage = "Error while uploding profile picture";
          this.snackbar = true;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.profilePictureUrl = url;
            this.isUploadSuccess = true;
            this.signUp();
          });
        }
      );
    },
  },
  async created() {
    this.checkCookie();
    this.colleges = await this.getColleges();
  },
};
</script>
<style scoped>
.backgruond {
  background-image: url(../assets/Order-Banner.jpg) !important;
  height: 300px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
}
</style>