<template>
  <v-app>
    <v-main>
      <v-app-bar color="primary">
        <v-row>
          <v-col cols="3" class="d-flex justify-center align-center">
            <img src="../assets/AALogo.png" height="50px" />
          </v-col>
          <v-col cols="1">
          </v-col>
          <v-col style="color: white" cols="4" class="d-flex justify-center align-center">
            <h1>STUDENT PARTNER PROGRAM</h1>
          </v-col>
          <v-col cols="4">
            <v-img src="../assets/Wallet.svg" width="50" height="50"> </v-img>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-row style="height: 710px">
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-img src="../assets/vector1.png"> </v-img>
        </v-col>
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-form @submit.prevent="submitHandler" ref="form">
            <div class="text-center">
              <v-avatar size="100" color="accent lighten-4">
                <v-icon size="40" color="accent">mdi-account</v-icon>
              </v-avatar>
              <h2 class="accent--text">Student Partner Login</h2>
            </div>
            <v-card-text class="pa-20" width="300">
              <v-text-field
                v-model="username"
                :rules="usernameRules"
                type="email"
                label="Email / PhoneNumber"
                placeholder="Email / PhoneNumber"
                prepend-icon="mdi-account"
                required
                solo
              />
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :type="passwordShow ? 'text' : 'password'"
                label="Password"
                placeholder="Password"
                prepend-icon="mdi-key"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
                required
                solo
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn :loading="loading" type="submit" color="accent">
                <span class="white--text px-8">Login</span>
              </v-btn>
              <v-btn outlined color="accent" @click="signUp">
                <span class="px-8">Register</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
      <a href="#top-performers" style="text-decoration: none; cursor: pointer">
        <v-icon
          size="100"
          color="secondary"
          class="d-flex justify-center align-center"
        >
          mdi-menu-down
        </v-icon>
      </a>
      <div id="top-performers">
        <v-sheet class="mx-auto ma-10" max-width="1000">
          <br />
          <br />
          <h2 class="d-flex justify-center align-center">
            Top Performers of the week
          </h2>
          <v-slide-group class="pa-4" v-model="model">
            <v-slide-item v-for="sp in topStudentPartners" :key="sp.Id">
              <v-card
                width="200"
                class="d-flex flex-column justify-center align-center ma-4"
                color="secondary"
              >
                <v-avatar class="ma-4" size="100">
                  <v-img :src="decodeURIComponent(sp.ProfilePictureUrl)">
                  </v-img>
                </v-avatar>
                <v-card-text style="color: white">
                  <b>Name: </b>{{ sp.Name }}
                  <br />
                  <b>College: </b>{{ sp.CollegeName }}
                  <br />
                  <b>Year: </b>{{ sp.Year }}
                  <br />
                  <b>Coins: </b>{{ sp.Coins }}
                  <sub>
                    <sub>
                      <img
                        src="../assets/Coin.svg"
                        alt=""
                        height="20"
                        width="20"
                      />
                    </sub>
                  </sub>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>

        <v-container class="grey lighten-5 mb-6">
          <h2 class="d-flex justify-center align-center">All colleges</h2>
          <v-row no-gutters>
            <v-col
              cols="4"
              class="pa-4"
              v-for="college in colleges"
              :key="college.Id"
              @click="openModal(college.Id, college.Name)"
            >
              <v-card
                color="accent"
                height="80"
                outlined
                style="cursor: pointer"
              >
                <v-row class="d-flex justify-center align-center pa-4">
                  <v-col cols="2">
                    <v-icon color="white" large> mdi-domain </v-icon>
                  </v-col>
                  <v-col cols="10" color="white" style="color: white">
                    {{ college.Name }}
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-footer
        color="primary"
        class="d-flex justify-center align-center pr-10 pl-10 pt-5 pb-5"
      >
        <a href="https://instagram.com/academic_affairs_" target="_blank"
          ><v-icon color="white" large> mdi-instagram </v-icon></a
        >
        &nbsp;&nbsp;&nbsp;
        <a href="https://discord.gg/UhuuJvXN8z" target="_blank"
          ><v-icon color="white" large> mdi-discord </v-icon></a
        >
        &nbsp;&nbsp;&nbsp;
        <a
          href="https://www.linkedin.com/company/academic-affairs-in"
          target="_blank"
          ><v-icon color="white" large> mdi-linkedin </v-icon></a
        >
      </v-footer>
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card color="white">
            <v-card-title class="d-flex justify-center align-center">
              Top Performers
            </v-card-title>
            <v-card-title class="d-flex justify-center align-center">
              {{ collegeName }}
              <v-icon color="secondary" large> mdi-domain </v-icon>
            </v-card-title>
            <v-card-text>
              <v-card
                v-for="sp in sps"
                :key="sp.Id"
                color="accent"
                class="ma-5 d-flex justify-center align-center"
              >
                <v-row
                  class="pa-4 d-flex justify-center align-center white--text"
                >
                  <v-col cols="2">
                    <v-avatar size="50">
                      <v-img :src="decodeURIComponent(sp.ProfilePictureUrl)" />
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <b>Name: </b>{{ sp.Name }}
                    <br />
                    <b>Year: </b>{{ sp.Year }}
                  </v-col>
                  <v-col cols="2">
                    {{ sp.Coins }}
                    <sub
                      ><sub
                        ><img
                          src="../assets/Coin.svg"
                          alt=""
                          height="20"
                          width="20" /></sub
                    ></sub>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-snackbar top :color="alertType" v-model="snackbar">
        {{ alertMessage }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",

  data: () => ({
    dialog: false,
    model: null,
    alertType: null,
    alertMessage: null,
    loading: false,
    snackbar: false,
    passwordShow: false,
    username: "",
    usernameRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be 6  characters or more!",
    ],
    topStudentPartners: [],
    colleges: [],
    collegeId: 0,
    collegeName: "",
    sps: [],
  }),
  computed: {
    loginData() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions([
      "Login",
      "getActiveStudentPartner",
      "getTopStudentPartners",
      "getHomeColleges",
      "getByCollegeId",
    ]),
    async openModal(collegeId, collegeName) {
      console.log(collegeId);
      this.dialog = true;
      this.collegeName = collegeName;
      this.sps = await this.getByCollegeId(collegeId);
    },
    signUp() {
      this.$router.push("/SignUp");
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      } else {
        this.$router.push("/Dashboard");
      }
    },
    async submitHandler() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (
          await this.Login(
            JSON.stringify({
              username: this.username,
              password: this.password,
            })
          )
        ) {
          this.loading = false;
          this.Alert("green", "Login Successful");
          this.getActiveStudentPartner();
          this.$router.push("/Dashboard");
        } else {
          this.Alert("red", "Login/Password is incorrect");
          this.loading = false;
        }
      }
    },
    Alert(type, message) {
      (this.alertType = type), (this.alertMessage = message);
      this.snackbar = true;
    },
    async init() {
      this.checkCookie();
      this.topStudentPartners = await this.getTopStudentPartners();
      this.colleges = await this.getHomeColleges();
    },
  },
  created() {
    this.init();
  },
};
</script>
