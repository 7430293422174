<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto ma-0" max-width="344" outlined>
          <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
              <div class="text-overline pa-3">Total</div>
              <v-card-title class="text-h5"> {{ coins }} </v-card-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="120" color="primary" class="ma-0">
              <v-icon size="60" color="white"> mdi-hand-coin-outline </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto ma-0" max-width="344" outlined>
          <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
              <div class="text-overline pa-3">Available</div>
              <v-card-title class="text-h5">
                {{ availableCoins }}
              </v-card-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="120" color="primary" class="ma-0">
              <v-icon size="60" color="white"> mdi-cash </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-form ref="form" @submit.prevent="requestAmount" v-model="valid">
            <v-text-field
              v-model="amount"
              label="Amount"
              type="number"
              :rules="amountRules"
              min="0"
              :max="availableCoins"
              dense
              required
              solo
              :disabled="availableCoins <= 500"
            ></v-text-field>
            <v-btn
              color="primary"
              style="width: 100%"
              :disabled="availableCoins <= 500"
              type="submit"
            >
              Request
              <v-icon small>mdi-hand-coin-outline</v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="6">
        <v-alert dense outlined v-if="availableCoins <= 500" type="error">
          Not enough <strong>coins</strong> available to withdraw.
        </v-alert>
        <h1 class="pa-5">Requests</h1>
        <v-alert dense outlined v-if="payouts.length == 0" type="error">
          No Requests available.
        </v-alert>
        <v-card>
          <v-row
            v-for="payout in payouts"
            :key="payout.Id"
            class="pa-5 d-flex align-center"
          >
            <v-col cols="3"> &#x20b9; {{ payout.Amount }} </v-col>
            <v-col cols="6">
              <b>Requested On:</b> {{ payout.CreatedAt.substring(0, 10) }}
              <b>Rewarded On:</b>
              {{ payout.UpdatedAt ? payout.UpdatedAt.substring(0, 10) : "--" }}
            </v-col>
            <v-col cols="3">
              <v-chip :color="getTTColor(payout.Status)">
                {{ getTTText(payout.Status) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    coins: 0,
    availableCoins: 0,
    payouts: [],
    type: 1,
    amount: null,
    valid: true,
    alertType: "",
    snackbar: false,
    alertMessage: "",
  }),
  methods: {
    ...mapActions([
      "getCoinsByStudentPartnerId",
      "getCoinPayoutByStudentPartnerId",
      "postPayoutRequest",
    ]),
    getTTColor(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "green";
      }
    },
    getTTText(status) {
      switch (status) {
        case 0:
          return "Pending..";
        case 1:
          return "Confirmed!";
      }
    },
    async requestAmount() {
      if (this.$refs.form.validate()) {
        var success = await this.postPayoutRequest({
          Amount: this.amount,
          StudentPartnerId: this.activeStudentPartnerId,
          Type: 1,
        });
        if (success) {
          this.payouts = await this.getCoinPayoutByStudentPartnerId(
            this.activeStudentPartnerId
          );
          this.$refs.form.reset();
          this.amount = null;
          this.valid = true;
          this.init();
          this.alertType = "green";
          this.alertMessage = "Request sent successfully";
          this.snackbar = true;
        }
      }
    },
    async init() {
      var coins = await this.getCoinsByStudentPartnerId(
        this.activeStudentPartnerId
      );
      (this.coins = coins["Coins"]),
        (this.availableCoins = coins["AvailableCoins"]);
      this.payouts = await this.getCoinPayoutByStudentPartnerId(
        this.activeStudentPartnerId
      );
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      }
    },
  },
  computed: {
    ...mapGetters(["activeStudentPartnerId"]),
    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v <= this.availableCoins ||
          "Amount should not exceed available coins",
        (v) => v >= 500 || "Amount should not be less than 500",
      ];
    },
  },
  async created() {
    this.checkCookie();
    this.init();
  },
};
</script>