<template>
  <span>
    <v-row class="d-flex justify-end mb-6" flat tile>
      <v-btn color="primary" @click="add">Add Task</v-btn>
    </v-row>

    <v-card>
      <v-card-title>
        Tasks
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="studentPartnerTasks"
        :search="search"
      >
        <template v-slot:item.SessionUrl="{ item }">
          <a
            :href="item.SessionUrl"
            target="_blank"
            style="text-decoration: none"
            v-if="item.SessionUrl"
          >
            Open Link
            <v-icon color="primary" small> mdi-open-in-new </v-icon>
          </a>
        </template>
        <template v-slot:item.SessionImageUrl="{ item }">
          <v-img :src="item.SessionImageUrl" width="100" height="100"> </v-img>
        </template>
        <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.Status == 0"
                @click.native="taskDelete(item.Id)"
                color="red"
                style="cursor: pointer"
              >
                &nbsp;mdi-delete
              </v-icon>
        </template>
        <template v-slot:item.Status="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="view(item.id)"
                v-bind="attrs"
                v-on="on"
                :color="getTTColor(item.Status)"
              >
                mdi-checkbox-blank-circle
              </v-icon>
            </template>
            <span>{{ getTTText(item.Status) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.Reward="{ item }">
          <span v-if="item.Reward">
            <b> {{ item.Reward }} </b>
            <sub><sub><img src="../assets/Coin.svg" alt="" height="20" width="20"></sub></sub>
          </span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary">Add a task</v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-card-text>
              <v-select
                v-model="taskType"
                :rules="taskRules"
                :items="tasksTypes"
                label="Task"
                solo
              ></v-select>
              <v-menu
                v-model="datemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="conductedOn"
                    :rules="conductedOnRules"
                    label="Conducted On"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="conductedOn"
                  @input="datemenu = false"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                v-model="sessionUrl"
                label="Session Url"
                solo
              ></v-text-field>

              <v-file-input
                show-size
                v-model="sessionImage"
                :rules="sessionImageRules"
                append-icon="mdi-camera"
                prepend-icon=""
                accept="image/png, image/jpeg, image/bmp"
                label="Choose Session Image"
                solo
              ></v-file-input>
              <v-textarea
                v-model="message"
                label="Message"
                counter
                maxlength="200"
                full-width
                single-line
                solo
              ></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                :loading="isLoading"
                color="primary"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import firebase from "firebase";

export default {
  data() {
    return {
      valid: true,
      search: "",
      isLoading: false,
      taskType: "",
      sessionUrl: "",
      conductedOn: "",
      sessionImageUrl: "",
      message: "",
      alertMessage: "",
      alertType: "",
      snackbar: false,
      dialog: false,
      loadingDialog: false,
      datemenu: false,
      studentPartnerTasks: [],
      sessionImage: null,
      sessionImageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      taskRules: [
        (v) => !!v || "Task type is required",
        (v) => (v && v != null) || "Task type is required",
      ],
      conductedOnRules: [
        (v) => !!v || "Conducted On is required",
        (v) => (v && v != null) || "Conducted On is required",
      ],
      headers: [
        {
          text: "Task Type",
          align: "start",
          sortable: false,
          value: "TaskTypeId",
        },
        { text: "Conducted On", value: "ConductedOn" },
        { text: "Description", value: "Message" },
        { text: "Url", value: "SessionUrl" },
        { text: "Image", value: "SessionImageUrl" },
        { text: "Reason", value: "Reason" },
        { text: "Status", value: "Status" },
        { text: "Reward", value: "Reward" },
        { text: "Action", value: "actions" },
      ],
      tasksTypes: [
        "Campaigning",
        "Interactive sessions (online)",
        "Instagram stories",
      ],
    };
  },
  computed: {
    ...mapGetters(["activeStudentPartnerId", "activeStudentPartnerTasks"]),
  },
  methods: {
    ...mapActions(["postTask", "getTasksByStudentId", "deleteTask"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.sessionImage == null) this.addTask();
        else this.uploadImage();
      }
    },
    getTTColor(status) {
      switch (status) {
        case 0:
          return "primary";
        case 1:
          return "red";
        case 2:
          return "green";
      }
    },
    getTTText(status) {
      switch (status) {
        case 0:
          return "Under Review";
        case 1:
          return "Rejected";
        case 2:
          return "Rewarded";
      }
    },
    add() {
      this.dialog = true;
    },
    uploadImage() {
      this.img1 = null;
      this.isLoading = true;
      const storageRef = firebase
        .storage()
        .ref(`TaskImages/${this.sessionImage.name}`)
        .put(this.sessionImage);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {
          this.alertType = 0;
          this.alertMessage = "Error while uploding profile picture";
          this.snackbar = true;
          this.isLoading = false;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.sessionImageUrl = url;
            this.isUploadSuccess = true;
            this.addTask();
          });
        }
      );
    },
    async addTask() {
      var task = {
        TaskTypeId: this.taskType,
        StudentPartnerId: this.activeStudentPartnerId,
        ConductedOn: this.conductedOn,
        SessionUrl: this.sessionUrl,
        SessionImageUrl: this.sessionImageUrl,
        Message: this.message,
      };
      var taskAdded = await this.postTask(task);
      if (taskAdded) {
        (this.message = ""), (this.dialog = false);
        this.alertMessage = "Added Task Successfully!";
        this.alertType = "green";
        this.snackbar = true;
        this.$refs.form.reset();
        this.isLoading = false;
        this.getStudentPartnerTasks();
      } else {
        this.alertMessage = "Error while Adding a Task!";
        this.alertType = "red";
        this.snackbar = true;
      }
    },
    resetForm() {
      this.taskType = "";
      this.conductedOn = "";
      this.sessionUrl = "";
      this.sessionImageUrl = "";
      this.message = "";
      this.sessionImage = null;
    },

    async taskDelete(id) {
      if (await this.deleteTask(id)) {
        this.alertMessage = "Deleted Task Successfully!";
        this.alertType = "red";
        this.snackbar = true;
        this.getStudentPartnerTasks();
      }
    },
    async getStudentPartnerTasks() {
      if (await this.getTasksByStudentId(this.activeStudentPartnerId)) {
        this.studentPartnerTasks = this.activeStudentPartnerTasks;
        this.loadingDialog = false;
      }
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      }
    },
  },
  created() {
    this.checkCookie();
    this.loadingDialog = true;
    this.getStudentPartnerTasks();
  },
};
</script>