<template>
  <span>
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col>
            <v-card color="white">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h6"> Coins Earned</v-card-title>
                  <v-card-title></v-card-title>
                  <v-card-subtitle class="text-h5">
                    {{ dashboardDetails.Coins }}
                  </v-card-subtitle>
                </div>

                <v-avatar class="ma-3" color="green darken-3" size="125" tile>
                  <v-icon size="60" color="white"> mdi-monitor-dashboard </v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="white">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h6"> Coins Redeemed</v-card-title>
                  <v-card-title></v-card-title>
                  <v-card-subtitle class="text-h5">
                    {{ dashboardDetails.RedeemedCoins }}
                  </v-card-subtitle>
                </div>

                <v-avatar class="ma-3" color="orange darken-3" size="125" tile>
                  <v-icon size="60" color="white"> mdi-cash </v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card color="white">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h6"> Referral Points Earned</v-card-title>
                  <v-card-title></v-card-title>
                  <v-card-subtitle class="text-h5">
                    {{ dashboardDetails.ReferralPointsEarned }}
                  </v-card-subtitle>
                </div>

                <v-avatar class="ma-3" color="green darken-3" size="125" tile>
                  <v-icon size="60" color="white"> mdi-link-variant </v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="white">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h6"> Total Referrals </v-card-title>
                  <v-card-title></v-card-title>
                  <v-card-subtitle class="text-h5">
                    {{ dashboardDetails.ReferralCount }}
                  </v-card-subtitle>
                </div>

                <v-avatar class="ma-3" color="orange darken-3" size="125" tile>
                  <v-icon size="60" color="white"> mdi-graph-outline </v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card class="d-flex justify-center align-center" height="320">
              <h2>More Features Coming Soon!!</h2>
        </v-card>
      </v-col>
    </v-row>

    <the-faq> </the-faq>
  </span>
</template>

<script>
import TheFaq from "../components/TheFaq.vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dashboardDetails: {},
    };
  },
  components: {
    TheFaq,
  },
  computed: {
    ...mapGetters(["activeStudentPartnerId"]),
  },
  methods: {
    ...mapActions(["getDashboardDetails"]),
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      }
    },
  },
  async created() {
    this.checkCookie();
    this.dashboardDetails = await this.getDashboardDetails(
      this.activeStudentPartnerId
    );
  },
};
</script>