import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import SignUp from "../views/SignUp.vue";
import Dashboard from "../views/Dashboard.vue";
import Tasks from "../views/Tasks.vue";
import ReferralPoints from "../views/ReferralPoints.vue";
import TheCoins from "../views/TheCoins.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "SP | Home",
    },
    component: Home,
  },
  {
    path: "/Home",
    name: "Home",
    meta: {
      title: "SP | Home",
    },
    component: Home,
  },
  {
    path: "/Login",
    name: "Login",
    meta: {
      title: "SP | Login",
    },
    component: Login,
  },
  {
    path: "/SignUp",
    name: "SignUp",
    meta: {
      title: "SP | SignUp",
    },
    component: SignUp,
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    meta: {
      title: "SP | Dashboard",
    },
    component: Dashboard,
  },
  {
    path: "/Profile",
    name: "Profile",
    meta: {
      title: "SP | Profile",
    },
    component: Profile,
  },
  {
    path: "/Tasks",
    name: "Tasks",
    meta: {
      title: "SP | Tasks",
    },
    component: Tasks,
  },
  {
    path: "/ReferralPoints",
    name: "ReferralPoints",
    meta: {
      title: "SP | ReferralPoints",
    },
    component: ReferralPoints,
  },
  {
    path: "/CoinsPayout",
    name: "CoinsPayout",
    meta: {
      title: "SP | CoinsPayout",
    },
    component: TheCoins,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
