import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeStudentPartner: {},
    navBarData: {
    },
    activeStudentPartnerId: 0,
    studentPartner: {},
    studentPartnerTasks: [],
    baseUrl: "",
  },
  getters: {
    activeStudentPartner: state => {
      return state.activeStudentPartner;
    },
    studentPartner: state => {
      return state.studentPartner;
    },
    activeStudentPartnerId: state => {
      return state.activeStudentPartnerId;
    },
    navBarData: state => {
      return {
        studentPartner: {
          ImageUrl: decodeURIComponent(state.activeStudentPartner.ProfilePictureUrl),
          Name: state.activeStudentPartner.Name,
        }
      };
    },
    profileData: state => {
      return {
        studentPartner: state.activeStudentPartner,
        linkedInScript: `<div class="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="light" data-type="VERTICAL"
                            data-vanity="XXProfileVanityXX" data-version="v1">
                            <a class="badge-base__link LI-simple-link" href="XXProfileLinkXX">
                            </a>
                        </div>
                        <script src="https://platform.linkedin.com/badges/js/profile.js" async defer type="text/javascript"></script>`
      };
    },
    activeStudentPartnerTasks: state => {
      return state.studentPartnerTasks
    }
  },
  mutations: {
    getActiveStudentPartner(state) {
      axios
        .get(
          `/StudentPartners/Get.php?Id=${state.activeStudentPartnerId}`
        )
        .then((response) => {
          state.activeStudentPartner = response.data["studentPartners"][0][0];
        })
        .catch(() => { });
    },
    setStudentPartner(state, studentPartner) {
      state.studentPartner = studentPartner;
    },
    Login(state, studentPartner) {
      state.activeStudentPartner = studentPartner
      state.activeStudentPartnerId = studentPartner.Id;
    },
    loginWithCookie(state, id) {
      state.activeStudentPartnerId = id;
    },
    logout(state) {
      state.activeStudentPartnerId = 0;
      state.activeStudentPartner = {};
      document.cookie =
        "currentUserId=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    putStudentPartner(state, studentPartner) {
      axios
        .put(
          `/StudentPartners/Put.php?Id=${state.activeStudentPartner.Id}}`,
          JSON.stringify(studentPartner)
        )
        .then((response) => {
          state.activeStudentPartner = response.data["studentPartners"][0][0];
        })
        .catch(() => { });
    },
    isLoggedIn(state) {
      if (state.activeStudentPartner.Id)
        return true;
      else
        return false;
    },
    setStudentPartnerTasks(state, studentPartnerTasks) {
      state.studentPartnerTasks = studentPartnerTasks;
    }
  },
  actions: {
    getActiveStudentPartner(context) {
      context.commit("getActiveStudentPartner");
    },
    async putStudentPartner(context, studentPartner) {
      try {
        var studentPartnerJSON = (JSON.stringify(studentPartner));
        var response = await axios
          .post(
            `/StudentPartners/Put.php`,
            studentPartnerJSON
          )
        if (response.status == 200)
          return true;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getStudentPartner(context, id) {
      try {
        var response = await axios
          .get(
            `/StudentPartners/Get.php?Id=${id}`
          )
        context.commit("setStudentPartner", response.data["studentPartners"][0][0])
        return true;
      } catch (error) {
        return false;
      }
    },
    isLoggedIn(context) {
      context.commit("isLoggedIn")
    },

    async Login(context, studentPartner) {
      try {
        var response = await axios
          .post(
            `/Login.php`,
            studentPartner
          )
        if (response.status == 200) {
          var expires = "";
          var date = new Date();
          date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
          document.cookie =
            "currentUserId" +
            "=" +
            (response.data["Id"] || "") +
            expires +
            "; path=/";
          context.commit("Login", response.data)
          return true;
        }
        else {
          return false;
        }
      }
      catch {
        return false
      }
    },
    loginWithCookie(context, id) {
      context.commit("loginWithCookie", id)
    },
    logout(context) {
      context.commit("logout")
    },
    async postStudentPartner(context, studentPartner) {
      try {
        var response = await axios
          .post(
            `/StudentPartners/Post.php`,
            JSON.stringify(studentPartner)
          )
        if (response.status == 201)
          return true;
        else
          return false
      } catch (error) {
        console.log(error)
        return error.message;
      }
    },
    async postTask(context, task) {
      try {
        var response = await axios
          .post(
            `/Tasks/Create.php`,
            JSON.stringify(task)
          )
        if (response.status == 201)
          return true;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async postCoinsTask(context, task) {
      try {
        var response = await axios
          .post(
            `/Tasks/PostReferralToCoins.php`,
            JSON.stringify(task)
          )
        if (response.status == 201)
          return true;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getTasksByStudentId(context, studentId) {
      try {
        var response = await axios
          .get(
            `/Tasks/GetByStudentPartnerId.php?StudentPartnerId=${studentId}`
          )
        context.commit("setStudentPartnerTasks", response.data["task"])
        return true;
      } catch (error) {
        return false;
      }
    },
    async deleteTask(context, id) {
      try {
        var response = await axios
          .get(
            `/Tasks/delete.php?Id=${id}`
          )
        if (response.status == 200)
          return true;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getCoinsByStudentPartnerId(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/Coins/GetByStudentPartnerId.php?StudentPartnerId=${studentPartnerId}`
          )
        if (response.status == 200)
          return response.data;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getCoinPayoutByStudentPartnerId(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/Payout/GetByStudentPartnerId.php?StudentPartnerId=${studentPartnerId}&Type=1`
          )
        if (response.status == 200)
          return response.data["payouts"];
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getReferralPointsByStudentPartnerId(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/ReferralPoints/GetByStudentPartnerId.php?StudentPartnerId=${studentPartnerId}`
          )
        if (response.status == 200)
          return response.data;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getReferralPointsPayoutByStudentPartnerId(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/Payout/GetByStudentPartnerId.php?StudentPartnerId=${studentPartnerId}&Type=2`
          )
        if (response.status == 200)
          return response.data["payouts"];
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async postPayoutRequest(context, payout) {
      try {
        var response = await axios
          .post(
            `/Payout/Post.php`,
            JSON.stringify(payout)
          )
        if (response.status == 201)
          return true;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getColleges() {
      try {
        var response = await axios
          .get(
            `/Colleges/Get.php`
          )
        if (response.status == 200)
          return response.data["colleges"];
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getHomeColleges() {
      try {
        var response = await axios
          .get(
            `/Colleges/GetHome.php`
          )
        if (response.status == 200)
          return response.data["colleges"];
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getTopStudentPartners() {
      try {
        var response = await axios
          .get(
            `/Coins/GetTop.php?top=5`
          )
        if (response.status == 200) {
          return response.data;
        }
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getByCollegeId(context, collegeId) {
      try {
        var response = await axios
          .get(
            `/StudentPartners/GetByCollegeId.php?CollegeId=${collegeId}`
          )
        if (response.status == 200) {
          return response.data;
        }
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getReferralPointsPayoutBySPId(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/ReferralPoints/GetBySPId.php?StudentPartnerId=${studentPartnerId}`
          )
        if (response.status == 200)
          return response.data;
        else
          return false
      } catch (error) {
        return false;
      }
    },
    async getDashboardDetails(context, studentPartnerId) {
      try {
        var response = await axios
          .get(
            `/Dashboard/GetByStudentPartnerId.php?StudentPartnerId=${studentPartnerId}`
          )
        if (response.status == 200)
          return response.data;
        else
          return false
      } catch (error) {
        return false;
      }
    }
  },
});
