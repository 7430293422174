import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#243665',
          //primary: '#1A237E',
          secondary: '#f44336',
          accent: '#2E42D1',
          error: '#b71c1c',

          // primary: '#26427A',
          // //primary: '#1A237E',
          // secondary: '#f8827a',
          // accent: '#5558ed',
          // error: '#b71c1c',
        },
      },
    },
  })
//   #0255FA
//   #0144C7
//   #012A7A
//   #26427A
export default vuetify;
