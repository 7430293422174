<template>
  <v-app>
    <div class="backgruond"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col lg="6" class="mx-auto">
        <v-card class="pa-0">
          <v-row>
            <v-col cols="6" class="pa-6">
              <div class="text-center">
                <v-avatar size="100" color="primary lighten-4">
                  <v-icon size="40" color="primary">mdi-account</v-icon>
                </v-avatar>
                <h2 class="primary--text">Student Partner Registration</h2>
              </div>
              <v-form @submit.prevent="submitHandler" ref="form">
                <v-card-text>
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    type="email"
                    label="Email / PhoneNumber"
                    placeholder="Email / PhoneNumber"
                    prepend-icon="mdi-account"
                    required
                    solo
                  />
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :type="passwordShow ? 'text' : 'password'"
                    label="Password"
                    placeholder="Password"
                    prepend-icon="mdi-key"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="passwordShow = !passwordShow"
                    required
                    solo
                  />
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn :loading="loading" type="submit" color="primary">
                    <span class="white--text px-8">Login</span>
                  </v-btn>
                  <v-btn outlined color="primary" @click="signUp">
                    <span class="px-8">Register</span>
                  </v-btn>
                </v-card-actions>

                <v-card-actions class="justify-center">
                  <v-btn large color="white" disabled>
                    <img src="../assets/google-logo.png" width="30" />
                    <span class="px-8">Google</span>
                  </v-btn>
                  <v-btn large color="white" disabled>
                    <img src="../assets/facebook-logo.png" width="30" />
                    <span class="px-8">Facebook</span>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-col>
            <v-col cols="6" class="d-flex align-center mb-6">
              <v-img src="../assets/vector1.png"> </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-main>

    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",

  data: () => ({
    alertType: null,
    alertMessage: null,
    loading: false,
    snackbar: false,
    passwordShow: false,
    username: "",
    usernameRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 6) || "Password must be 6  characters or more!",
    ],
  }),
  computed: {
    loginData() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions(["Login", "getActiveStudentPartner"]),
    signUp() {
      this.$router.push("/SignUp");
    },
    async submitHandler() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (
          await this.Login(
            JSON.stringify({
              username: this.username,
              password: this.password,
            })
          )
        ) {
          console.log(1)
          this.loading = false;
          this.Alert("green", "Login Successful");
          this.getActiveStudentPartner();
          this.$router.push("/Dashboard");
        } else {
          this.Alert("red", "Login/Password is incorrect");
          this.loading = false;
        }
      }
    },
    Alert(type, message) {
      (this.alertType = type), (this.alertMessage = message);
      this.snackbar = true;
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (allCookies.currentUserId) {
        this.$router.push("/Dashboard");
      }
    },
  },
  created() {
    this.checkCookie();
  },
};
</script>
<style scoped>
.backgruond {
  background-image: url(../assets/Order-Banner.jpg) !important;
  height: 300px;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
}
</style>