<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="70"
      color="primary"
      app
      dense
    >
      <v-sheet class="pa-4" color="primary" v-if="!mini">
        <v-avatar class="mb-4" size="100">
          <v-img :src="navBarData.studentPartner.ImageUrl"> </v-img>
        </v-avatar>
        <div style="color:white">{{ navBarData.studentPartner.Name }}</div>
      </v-sheet>

      <v-list-item class="px-2" v-else>
        <v-list-item-avatar>
          <v-img :src="navBarData.studentPartner.ImageUrl">
          </v-img>
        </v-list-item-avatar>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, toLink] in links"
          :key="icon"
          link
          :to="toLink"
          custom
        >
          <v-list-item-icon>
            <v-icon color="white">{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title color="white" style="color:white">{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary">
      <v-btn icon @click.stop="openDrawer" color="white">
        <v-icon>
          {{ mini || miniDrawer ? "mdi-chevron-right" : "mdi-chevron-left" }}
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        justify-end="true"
        color="red"
        depressed
        elevation="2"
        outlined
        @click="logoutUser"
      >
        <v-icon left> mdi-logout </v-icon> Logout
      </v-btn>
    </v-app-bar>
    <slot name="main"></slot>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  data: () => ({
    mini: true,
    miniDrawer: false,
    drawer: null,
    links: [
      ["mdi-monitor-dashboard", "Dashboard", "/Dashboard"],
      ["mdi-account", "My Profile", "/Profile"],
      ["mdi-format-list-checks", "Tasks", "/Tasks"],
      ["mdi-link-variant", "My Referrals", "/ReferralPoints"],
      ["mdi-hand-coin-outline", "CoinsPayout", "/CoinsPayout"],
    ],
  }),
  watch: {
    drawer(oldVal, newVal) {
      if (newVal == false) {
        this.mini = false;
        this.miniDrawer = true;
      }
    },
  },
  methods: {
    ...mapActions(["isLoggedIn", "logout"]),
    logoutUser() {
      this.logout();
      this.$router.push("/Home");
    },
    openDrawer() {
      this.drawer = true;
      this.mini = !this.mini;
    },
  },
  created() {},
  computed: {
    minidrawer() {
      return !this.drawer;
    },
    ...mapGetters(["navBarData"]),
  },
};
</script>

<style scoped>
.profile-image {
  background-image: url(../assets/Avatar.png);
  background-size: 110px 110px;
}
</style>