<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto ma-0" max-width="344" outlined>
          <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
              <div class="text-overline pa-3">Total</div>
              <v-card-title class="text-h5">
                {{ referralPoints }}
              </v-card-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="120" color="primary" class="ma-0">
              <v-icon size="60" color="white"> mdi-link-variant </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto ma-0" max-width="344" outlined>
          <v-list-item class="pa-0">
            <v-list-item-content class="pa-0">
              <div class="text-overline pa-3">Available</div>
              <v-card-title class="text-h6">
                {{ availableReferralPoints }}
              </v-card-title>
            </v-list-item-content>
            <v-list-item-avatar tile size="120" color="primary" class="ma-0">
              <v-icon size="60" color="white"> mdi-cash </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-3">
          <v-form ref="form" @submit.prevent="requestAmount" v-model="valid">
            <v-text-field
              v-model="amount"
              label="Amount"
              type="number"
              :rules="amountRules"
              min="0"
              :max="availableReferralPoints"
              dense
              required
              solo
              :disabled="availableReferralPoints <= 250"
            ></v-text-field>
            <v-btn
              color="primary"
              style="width: 100%"
              :disabled="availableReferralPoints <= 250"
              type="submit"
            >
              Convert to coins
              <v-icon small>mdi-link-variant</v-icon>
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-row class="d-flex justify-center align-center">
      <v-col cols="6">
        <v-alert
          dense
          outlined
          v-if="availableReferralPoints <= 250"
          type="error"
        >
          Not enough <strong>Referral Points</strong> available for converting
          into coins.
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-card>
        <v-card-title>
          Referrals
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="referralPointsList"
          :search="search"
        >
        </v-data-table>
      </v-card>
    </v-row>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    referralPoints: 0,
    availableReferralPoints: 0,
    payouts: [],
    type: 2,
    amount: null,
    valid: true,
    alertType: "",
    snackbar: false,
    alertMessage: "",
    referralPointsList: [],
    search: "",
    headers: [
      { text: "Student", value: "StudentId" },
      { text: "Refered On", value: "CreatedAt" },
      { text: "Commission", value: "ReferralCommission" },
    ],
  }),
  methods: {
    ...mapActions([
      "getReferralPointsByStudentPartnerId",
      "getReferralPointsPayoutByStudentPartnerId",
      "getReferralPointsPayoutBySPId",
      "postPayoutRequest",
      "postCoinsTask",
    ]),
    getTTColor(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "green";
      }
    },
    getTTText(status) {
      switch (status) {
        case 0:
          return "Pending..";
        case 1:
          return "Confirmed!";
      }
    },
    async requestAmount() {
      if (this.$refs.form.validate()) {
        var task = {
          TaskTypeId: "Convert to coins",
          StudentPartnerId: this.activeStudentPartnerId,
          ConductedOn: "-1",
          Message: "Converted to coins",
          CoinsRewarded: this.amount,
          Status: 2,
        };
        var taskAdded = await this.postCoinsTask(task);
        if (taskAdded) {
          (this.message = ""), (this.dialog = false);
          this.alertMessage = "Added Task Successfully!";
          this.alertType = "green";
          this.snackbar = true;
          this.$refs.form.reset();
          this.isLoading = false;
          this.init();
        } else {
          this.alertMessage = "Error while Adding a Task!";
          this.alertType = "red";
          this.snackbar = true;
        }
      }
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      }
    },
    async init() {
      var referralPoints = await this.getReferralPointsByStudentPartnerId(
        this.activeStudentPartnerId
      );
      this.referralPointsList = await this.getReferralPointsPayoutBySPId(
        this.activeStudentPartnerId
      );
      (this.referralPoints = referralPoints["ReferralPoints"]),
        (this.availableReferralPoints =
          referralPoints["AvailableReferralPoints"]);
      this.payouts = await this.getReferralPointsPayoutByStudentPartnerId(
        this.activeStudentPartnerId
      );
    },
  },
  computed: {
    ...mapGetters(["activeStudentPartnerId"]),
    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v <= this.availableReferralPoints ||
          "Amount should not exceed available referral points",
        (v) => v >= 250 || "Amount should not be less than 250",
      ];
    },
  },
  async created() {
    this.checkCookie();
    this.init();
  },
};
</script>