<template>
  <span>
    <v-row justify="center">
      <img src="../assets/faq-unscreen.gif" alt="" />
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-expansion-panels popout>
          <v-expansion-panel v-for="faq in faqs" :key="faq.A">
            <v-expansion-panel-header>{{ faq.Q }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ faq.A }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div style="margin:15px">
          <b style="color: red"> Note: </b>Referral Points feature is not yet
          available, You can get complete information about it once it starts working.
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          Q: "What are Coins?",
          A: "Academic Affairs will be giving tasks to the Student Partners, like campaingns, online sessions etc. The Student Partners will be awarded with coins once the task is completed.1 Coin = 1 Rupee",
        },
        {
          Q: "When can we Redeem our coins?",
          A: "The Student Partners will be able to redeem the coins once they reach a minimun of 500 Coins. 1 Coin = 1 Rupee",
        },
        {
          Q: "How to Redeem Coins?",
          A: "Once the Student Partner reaches a minimum of 500 Coins, he or she can raise a request of 500 and more from Coins Payout Tab. They can see the Request pending and it will be changed to success, once the money is transferred.",
        },
        {
          Q: "What are Referral Points?",
          A: "The Student Partners will be able to send their own referral links of the courses or events we offer, to the students. The Student Partners will be awarded with referral points as the commision.",
        },
        {
          Q: "How to convert Referral Points to Coins?",
          A: "The Student Partner can convert the referral points to Coins when he or she reaches a minumum of 250 points.",
        },
      ],
    };
  },
};
</script>