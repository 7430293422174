import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from 'firebase/app';
import axios from "axios";

Vue.config.productionTip = false;
firebase.initializeApp({
  apiKey: "AIzaSyC6L9hLGb4fPUpA44qIyn976rG8e3yRYNU",
  authDomain: "student-partner-73835.firebaseapp.com",
  projectId: "student-partner-73835",
  storageBucket: "student-partner-73835.appspot.com",
  messagingSenderId: "630466948101",
  appId: "1:630466948101:web:57e3c6c83f38868721406c",
  measurementId: "G-1FQQC38ZVZ"
});
axios.defaults.baseURL = "http://studentpartner.academicaffairs.in/api/";
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");