<template>
  <span>
    <v-row>
      <v-col cols="3"> </v-col>
      <v-col cols="6">
        <v-card class="pa-10">
          <v-row align="center" class="mx-auto">
            <v-col> </v-col>
            <v-col>
              <v-avatar align="center" color="primary" size="150">
                <img
                  :src="decodeURIComponent(studentPartner.ProfilePictureUrl)"
                />
              </v-avatar>
            </v-col>
            <v-col> </v-col>
          </v-row>
          <br />
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
              outlined
            >
            </v-text-field>

            <v-file-input
              show-size
              v-model="profilePicture"
              :rules="profilePictureRules"
              append-icon="mdi-camera"
              prepend-icon=""
              accept="image/png, image/jpeg, image/bmp"
              label="Choose Profile Image"
              outlined
            ></v-file-input>

            <v-text-field
              v-model="emailId"
              :rules="emailIdRules"
              label="E-mail"
              required
              outlined
              disabled
            >
            </v-text-field>

            <v-autocomplete
              v-model="collegeId"
              :items="colleges"
              :rules="collegeRules"
              label="College"
              item-text="Name"
              item-value="Id"
              outlined
            ></v-autocomplete>

            <v-select
              v-model="year"
              :rules="yearRules"
              :items="years"
              label="Year Of Study"
              outlined
            ></v-select>

            <v-text-field
              v-model="upiId"
              :rules="upiIdRules"
              label="UPI Id"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="rollNumber"
              label="Roll Number"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="phoneNumber"
              :counter="10"
              label="Mobile Number"
              required
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="linkedInProfileLink"
              label="LinkedIn Profile"
              required
              outlined
            >
            </v-text-field>

            <v-btn color="primary" type="submit" :loading="loading">
              Update
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="3">
        <iframe
          :srcdoc="profileData.linkedInScript"
          width="300px"
          height="400px"
          frameborder="0"
        ></iframe>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import firebase from "firebase";

export default {
  data: () => ({
    loading: false,
    upiId: "",
    upiIdRules: [(v) => !!v || "UPI ID is required"],
    alertMessage: "",
    alertType: "",
    snackbar: false,
    dialog: false,
    valid: true,
    profilePicture: null,
    sp: {},
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 45) || "Name must be less than 45 characters",
    ],
    emailIdRules: [
      (v) => !!v || "E-mail Id is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    collegeRules: [
      (v) => !!v || "college is required",
      (v) => (v && v != null) || "College is required",
    ],
    profilePictureRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    name: "",
    emailId: "",
    year: "",
    profilePictureUrl: "",
    phoneNumber: "",
    linkedInProfileLink: "",
    collegeId: null,
    yearRules: [(v) => !!v || "Year is required"],
    colleges: [],
    rollNumber: "",
    years: ["1", "2", "3", "4"],
  }),
  computed: {
    ...mapGetters(["profileData", "studentPartner", "activeStudentPartnerId"]),
  },
  methods: {
    ...mapActions([
      "putStudentPartner",
      "getActiveStudentPartner",
      "getStudentPartner",
      "getColleges",
    ]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.profilePicture == null) this.update();
        else this.uploadImage();
      }
    },
    uploadImage() {
      this.img1 = null;
      const storageRef = firebase
        .storage()
        .ref(`SpProfilePics/${this.profilePicture.name}`)
        .put(this.profilePicture);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        () => {
          this.alertType = 0;
          this.alertMessage = "Error while uploding profile picture";
          this.snackbar = true;
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.profilePictureUrl = url;
            this.isUploadSuccess = true;
            this.update();
          });
        }
      );
    },
    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (
          await this.putStudentPartner({
            Id: this.activeStudentPartnerId,
            Name: this.name,
            ProfilePictureUrl: encodeURIComponent(this.profilePictureUrl),
            CollegeId: this.collegeId,
            Year: this.year,
            RollNumber: this.rollNumber,
            UPIId: this.upiId,
            EmailId: this.emailId,
            PhoneNumber: this.phoneNumber,
            LinkedInProfileLink: this.linkedInProfileLink,
          })
        ) {
          this.alertMessage = "Updated Successfully!";
          this.alertType = "green";
          this.snackbar = true;
          this.loading = false;
          this.init();
        } else {
          this.alertMessage = "Update failed!";
          this.alertType = "red";
          this.snackbar = true;
          this.loading = false;
        }
      }
    },
    checkCookie() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        this.$router.push("/Home");
      }
    },
    async init() {
      if (await this.getStudentPartner(this.activeStudentPartnerId)) {
        this.dialog = false;
        this.name = this.studentPartner.Name;
        this.emailId = this.studentPartner.EmailId;
        this.phoneNumber = this.studentPartner.PhoneNumber;
        this.profilePictureUrl = decodeURIComponent(
          this.studentPartner.ProfilePictureUrl
        );
        this.linkedInProfileLink = this.studentPartner.LinkedInProfileLink;
        this.collegeId = this.studentPartner.CollegeId;
        this.rollNumber = this.studentPartner.RollNumber;
        this.year = this.studentPartner.Year;
        this.upiId = this.studentPartner.UPIId;
      }
      this.getActiveStudentPartner();
      this.colleges = await this.getColleges();
    },
  },
  created() {
    this.checkCookie();
    this.dialog = true;
    this.init();
  },
};
</script>