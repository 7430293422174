<template>
  <span>
    <nav-bar v-if="activeStudentPartnerId && !isHomePage">
      <v-main class="main" slot="main">
        <v-container class="py-8 px-6" fluid>
          <router-view />
        </v-container>
      </v-main>
    </nav-bar>
    <router-view v-else />
  </span>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  data() {
    return {
      isHomePage: false,
    };
  },
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters(["activeStudentPartner", "activeStudentPartnerId"]),
  },
  watch: {
        $route: {
            handler: (to) => {
                document.title = to.meta.title || "AA";
            },
            immediate: true,
        },
    },
  methods: {
    ...mapActions(["getActiveStudentPartner", "loginWithCookie"]),
    init() {
      if (this.$route.path == "/Home" || this.$route.path == "/") {
        this.isHomePage = true;
        return;
      }
      else {
        this.isHomePage = false;
      }
      if(this.$route.path != "/SignUp")
      this.GetActiveUserDetails();
    },
    GetActiveUserDetails() {
      var allCookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      if (!allCookies.currentUserId) {
        if (!this.$route.name == "SignUp") this.$router.push("/Home");
      }
      this.loginWithCookie(allCookies.currentUserId);
      this.getActiveStudentPartner();
      if (this.activeStudentPartnerId) {
        if (this.$route.name == "Login") {
          this.$router.push("/Dashboard");
        }
      }
    },
  },
  created() {
    this.init();
  },
  updated() {
    this.init();
  }
};
</script>

<style scoped>
.main {
  background-color: #f1f1f1;
}
</style>